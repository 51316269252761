








































































































































































































































































































import { Component } from 'vue-property-decorator'
import { Action, Getter, State } from 'vuex-class'
import { GET_JOB_AD, GET_JOB_AD_MATCHING_RESULTS } from '@/store/jobAd/actions'
import { JobAdType, JobAdSpecs, MatchingResult, MatchingResultSet } from '@/store/jobAd/types'
import ResultsPage from '@/components/search-results/ResultsPage.vue'
import SearchResult from '@/components/search-results/SearchResult.vue'
import bootstrapBreakPoints from '@/common/breakpointsBootstrap'
import { SortingInfo } from '@/store/commonFormInput/types'
import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
import InquiryModal from '@/components/forms/InquiryModal.vue'
import ConfirmationModal from '@/components/common/ConfirmationModal.vue'
import { ApplicationModel, InquiryDto } from '@/store/inquiry/types'
import { ACCEPT_DECLINE, INQUIRE_TALENT, INQUIRE_TALENT_CANCEL } from '@/store/inquiry/actions'
import { GenericRequest, SimpleServerResponse, User } from '@/store/types'
import Enums from '@/common/Enums'
import { hasRight } from '@/utils/rights'
import Rights from '@/common/rights'
import {
  ACCEPT,
  DECLINE,
  getAcceptDeclinePositiveFeedback,
  getCompanyInquiryConfirmationHeadingForStudent, getTalentRequestConfirmationExplanationForCompany, sendTalentInquiry
} from '@/common/inquiry'
import MetaComponent from '@/common/MetaComponent.vue'
import InfoBox from '@/components/hszg/InfoBox.vue'

@Component({
  components: { InquiryModal, LoadingSpinner, SearchResult, ResultsPage, ConfirmationModal, InfoBox }
})
export default class MatchingResultsDetail extends MetaComponent {
  @State('currentScreenWidth') screenWidth: number

  @Action(GET_JOB_AD) getJobAd: (jobAdSpec: JobAdSpecs) => Promise<JobAdType>

  @Action(GET_JOB_AD_MATCHING_RESULTS)
  getJobAdMatchingResults: (jobAdId: string) => Promise<MatchingResult>

  @Action(INQUIRE_TALENT)
  inquireTalent: (requestDto: InquiryDto) => Promise<InquiryDto>

  @Action(INQUIRE_TALENT_CANCEL)
  inquireTalentCancel: (requestDto: GenericRequest) => Promise<SimpleServerResponse>

  @Action(ACCEPT_DECLINE)
  public acceptOrDecline: (dto: GenericRequest) => Promise<SimpleServerResponse>

  @Getter('userGetter') userGetter: User

  isLoading = false
  showErrorMessage = false
  errorMessage = ''
  modalHeading = ''
  modalExplanation = ''
  desiredAction = ''
  inquiryId = ''
  inquiryButtons: string[] = []

  maxPerColumn = 1

  jobAd: JobAdType = {
    title: ''
  }

  jobAdId = ''

  respondent: MatchingResultSet | undefined
  showInquiryWarning = false

  matchingResults: MatchingResult = { perfectMatches: [], insufficientMatches: [] }

  get DECLINE (): string {
    return DECLINE
  }

  get ACCEPT (): string {
    return ACCEPT
  }

  get lg (): boolean {
    return this.screenWidth < bootstrapBreakPoints.lg
  }

  get sortable (): SortingInfo[] {
    return [
      { key: 'averageMatchingResult', label: this.$i18n.t('job-ad.matching.average').toString() },
      { key: 'competenceMatchingResult', label: this.$i18n.t('job-ad.matching.competence').toString() + this.$i18n.t('job-ad.matching.matching').toString() },
      { key: 'qualificationMatchingResult', label: this.$i18n.t('job-ad.matching.qualification').toString() + this.$i18n.t('job-ad.matching.matching').toString() }
    ]
  }

  get canInquire (): boolean {
    return hasRight(Rights.INQUIRE_TALENT)
  }

  get canReact (): boolean {
    return hasRight(Rights.INQUIRY_ANSWER)
  }

  get inquiryGetter () {
    return Enums
  }

  getInquiryButton (talent: MatchingResultSet): string | null | undefined {
    if (!this.canInquire) {
      return Enums.NOT_ALLOWED
    } else {
      return talent.inquiryStatus
    }
  }

  openRequestModal (talent: MatchingResultSet): void {
    this.respondent = talent
    this.showInquiryWarning = false
    this.$root.$emit('dismiss-alert')
    this.$bvModal.show('talent-inquiry-modal')
  }

  openConfirmationModal (action: string, inquiryId: string, talent: MatchingResultSet): void {
    this.respondent = talent
    this.modalExplanation = getTalentRequestConfirmationExplanationForCompany(action)
    this.modalHeading = getCompanyInquiryConfirmationHeadingForStudent(action)
    this.desiredAction = action
    this.inquiryId = inquiryId
    this.$bvModal.show('confirmation-modal-inquiry')
  }

  sendTalentInquiry (inquiryText: ApplicationModel): void {
    this.$root.$emit('bv::hide::modal', 'talent-inquiry-modal')
    this.$root.$emit('load')
    sendTalentInquiry(
      this,
      this.userGetter.appUserId,
      this.jobAd.jobAdId as string,
      this.respondent?.profileStudentId as string,
      inquiryText
    ).then((data: InquiryDto) => {
      if (this.respondent !== undefined) {
        this.respondent.inquiryStatus = data.inquiryStatus
        this.respondent.inquiryId = data.inquiryId
        this.$root.$emit('alert', this.$i18n.t('info.success').toString(), this.$i18n.t('job-ad.matching.inquiry-successful').toString())
      }
      this.respondent = undefined
    }).catch((error) => {
      this.errorMessage = error
      this.showInquiryWarning = true
    }).finally(() => {
      this.$root.$emit('end-load')
    })
  }

  inquiryResponse (inquiryId: string): void {
    let acceptInquiry = 'false'
    if (this.desiredAction === ACCEPT) {
      acceptInquiry = 'true'
    }
    this.$root.$emit('load')
    this.acceptOrDecline({ query: [acceptInquiry, inquiryId] }).then(() => {
      if (this.respondent !== undefined) {
        this.respondent.inquiryStatus = this.inquiryGetter.INQUIRY_DENIED_BY_COMPANY
        if (acceptInquiry === 'true') {
          this.respondent.inquiryStatus = this.inquiryGetter.INQUIRY_ACCEPTED_BY_COMPANY
        }
        this.respondent.inquiryId = inquiryId
      }
      const message = getAcceptDeclinePositiveFeedback(this.desiredAction)
      this.inquiryButtons = this.desiredAction === ACCEPT ? [DECLINE] : [ACCEPT]
      this.$root.$emit('alert', message, '', false)
      this.respondent = undefined
    }).catch(() => {
      this.$root.$emit('alert', this.$i18n.t('warning.general-error-message').toString(), '', true)
    }).finally(() => {
      this.$root.$emit('end-load')
    })
  }

  openCancellationConfirmModal (talent: MatchingResultSet): void {
    this.respondent = talent
    this.$bvModal.show('confirmation-modal-cancel-inquiry')
  }

  cancelInquiry (): void {
    if (this.respondent !== undefined) {
      this.$root.$emit('load')
      const requestDto: GenericRequest = {
        query: [this.respondent.inquiryId as string],
        params: undefined
      }
      this.inquireTalentCancel(requestDto).then(() => {
        if (this.respondent !== undefined) {
          this.respondent.inquiryStatus = null
          this.respondent.inquiryId = null
          this.$root.$emit('alert', this.$i18n.t('job-ad.matching.inquiry-canceled').toString(), '')
        }
        this.respondent = undefined
      }).catch(error => {
        this.errorMessage = error
      }).finally(() => {
        this.$root.$emit('end-load')
      })
    }
  }

  created (): void {
    this.loadData()
  }

  loadData (): void {
    this.jobAdId = this.$route.params.id

    if (this.jobAdId) {
      this.isLoading = true
      Promise.all([
        this.getJobAd({ id: this.jobAdId, isOwn: true }),
        this.getJobAdMatchingResults(this.jobAdId)
      ]).then((data) => {
        this.jobAd = { ...data[0] }
        this.matchingResults = data[1]
      }).catch(() => {
        this.$root.$emit('alert',
          this.$t('warning.error!').toString(),
          this.$tc('warning.could-not-load', 2, { what: this.$tc('job-ad.matching.talent', 2) }).toString() + ' ' + this.$t('warning.try-again'),
          true)
      }).finally(() => {
        this.isLoading = false
      })
    }
  }
}
